<template>
  <div>
    <iframe :src='frame' width='100%' height='1200px' frameborder='0'></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import { formatPrice, sumdate } from '@/plugins/filters'
import vatDataPrint from '@/@fake-db/data/vatDataPrint.json'
import 'jspdf-autotable'
import shop from '@/api/systemSetting/shop'
import shopFixOnly from '@/@fake-db/data/shopFix/shopFixOnly.js'

export default {
  setup() {

    const { route } = useRouter()
    const vatList = ref(vatDataPrint.data)
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const dataTableList = ref([])
    const fontTitle = ref(15)
    const fontBody = ref(14)
    const lastTable = ref(0)
    const shop_print_type = ref('0')
    const shopData = ref({})
    const receiptData = ref({})
    const topic = ref(route.value.query.topic || '1')
    const point = ref(route.value.query.point)
    const comment = ref(route.value.query.showComment)
    const frame = ref('')
    let currentShopId = ''
    let isShopFix = false
    let isChangeY = 0

    i18n.locale = route.value.query.lang == '2' ? 'en' : 'th'
    const printsOrder = () => {
      prints
        .printsOrder({
          order_id_pri: route.value.query.id,
          lang: i18n.locale,
        })
        .then(res => {
          dataTableList.value = res.orderdetail
          receiptData.value = res
          savePDF(res)
        })
    }
    shop.shopGet().then(res => {
      shop_print_type.value = route.value.query.shop_print_type
      currentShopId = res.shop_id
      shopData.value = res
      findShop()
      printsOrder()
    })

    const findShop = () => {
      let x = shopFixOnly.shopFixOnly.find((item) => {
        return item === currentShopId
      })
      if (x) {
        isShopFix = true
      }
      if (isShopFix && topic.value == '2') {
isChangeY = 5
      } else {
        isChangeY = 0
      }
    }
    const generateData = () => {
      const result = []
      let drugTotal = 0
      let count = 0
      let drugDiscount = 0
      let priceUnit = 0
      let name = ''

      dataTableList.value.forEach((item, index) => {
        // drug
        if (route.value.query.showDrugStatus == 'true' && route.value.query.showCourseStatus == 'false') {
          if (item.orderdetail_type_id == '2') {
            count += 1
            drugTotal += +item.orderdetail_total.replaceAll(',', '')
            drugDiscount += +item.orderdetail_discount.replaceAll(',', '')
            priceUnit += +item.orderdetail_price.replaceAll(',', '')
            name = i18n.t('drug_and_service')
          } else {
            result.push([
              `${item.orderdetail_name} (${item.orderdetail_id})`,
              `${item.orderdetail_amount}`,
              item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
              item.orderdetail_price,
              item.orderdetail_discount,
              item.orderdetail_total,
            ])
          }
          if (index + 1 == dataTableList.value.length && count != 0) {
            result.push([
              `${name}`,
              '1.00',
              i18n.t('times'),
              formatPrice(drugTotal),
              formatPrice(drugDiscount),
              formatPrice(drugTotal),
            ])
          }
        }

        // check and course
        if (route.value.query.showCourseStatus == 'true' && route.value.query.showDrugStatus == 'false') {
          if (item.orderdetail_type_id == '1' || item.orderdetail_type_id == '3') {
            count += 1
            drugTotal += +item.orderdetail_total.replaceAll(',', '')
            drugDiscount += +item.orderdetail_discount.replaceAll(',', '')
            priceUnit += +item.orderdetail_price.replaceAll(',', '')
            name = i18n.t('service_and_treatment_fees')
          } else {
            result.push([
              `${item.orderdetail_name} (${item.orderdetail_id})`,
              `${item.orderdetail_amount}`,
              item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
              item.orderdetail_price,
              item.orderdetail_discount,
              item.orderdetail_total,
            ])
          }
          if (index + 1 == dataTableList.value.length && count != 0) {
            result.push([
              `${name}`,
              '1.00',
              i18n.t('times'),
              formatPrice(drugTotal),
              formatPrice(drugDiscount),
              formatPrice(drugTotal),
            ])
          }
        }

        // all check
        if (route.value.query.showCourseStatus == 'true' && route.value.query.showDrugStatus == 'true') {
          if (item.orderdetail_type_id == '1' || item.orderdetail_type_id == '2' || item.orderdetail_type_id == '3') {
            count += 1
            drugTotal += +item.orderdetail_total.replaceAll(',', '')
            drugDiscount += +item.orderdetail_discount.replaceAll(',', '')
            priceUnit += +item.orderdetail_price.replaceAll(',', '')
            name = i18n.t('drug_and_treatment_fees')
          } else {
            result.push([
              `${item.orderdetail_name} (${item.orderdetail_id})`,
              `${item.orderdetail_amount}`,
              item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
              item.orderdetail_price,
              item.orderdetail_discount,
              item.orderdetail_total,
            ])
          }
          if (index + 1 == dataTableList.value.length && count != 0) {
            result.push([
              `${name}`,
              '1.00',
              i18n.t('times'),
              receiptData.value.order_price,
              formatPrice(drugDiscount),
              receiptData.value.order_price,
            ])
          }
        }

        // bold
        if (route.value.query.showDrugStatus == 'false' && route.value.query.showCourseStatus == 'false') {
          result.push([
            `${item.orderdetail_name} (${item.orderdetail_id})`,
            `${item.orderdetail_amount}`,
            item.orderdetail_unit == 'null' || null ? i18n.t('times') : item.orderdetail_unit,
            item.orderdetail_price,
            item.orderdetail_discount,
            item.orderdetail_total,
          ])
        }
      })
      result.map((item, index) => (item[0] = `${index + 1}) ${item[0]}`))

      return result
    }

    const headers = [
      `${i18n.t('list')}`,
      i18n.t('amount'),
      i18n.t('unit'),
      i18n.t('price_unit'),
      i18n.t('discount_unit'),
      i18n.t('total_amount'),
    ]

    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'portrait',
        format: 'a4',
      })

      doc.setProperties({
        title: `RECEIPT ${data.order_id} - ${data.order_create}`,
      })

      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-Bold-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
      doc.addFont('TH-Niramit-AS-Bold-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'bold')

      doc.autoTable({
        theme: 'plain',
        styles: {
          font: 'TH-Niramit',
          fontStyle: 'bold',
          textColor: '#212121',
          fontSize: fontBody.value,
          halign: 'left',
        },
        showFoot: 'lastPage',
        headStyles: {
          textColor: '#1E88E5',
          fontStyle: 'bold',
          fontSize: fontTitle.value,
          halign: 'center',
        },
        columnStyles: {
          1: { halign: 'center', fillColor: '#F5F5F5', cellWidth: 16 },
          2: { halign: 'center', cellWidth: 18 },
          3: { halign: 'right', fillColor: '#F5F5F5', cellWidth: 24 },
          4: { halign: 'right', cellWidth: 26 },
          5: { halign: 'right', fillColor: '#F5F5F5', cellWidth: 22 },
        },
        startY: 85,
        margin: {
          top: 85,
          left: 5,
          right: 5,
          bottom: 90,
        },

        head: [headers],
        body: generateData(),
        didDrawPage(datax) {
          // const ImageToLoad = new Image()
          // ImageToLoad.src = shopData.value.shop_image
          doc.addImage(JSON.parse(localStorage.getItem('shopImage')), 'JPEG', 7, 6, 28, 26)

          // Start Address Shop block
          if (shop_print_type.value === '1') {
            doc.setFontSize(fontTitle.value)
            doc.setFillColor('#212121')
            doc.text(`${data.shop_name} ${data.shop_nature}`, 40, 10)
            doc.setFont('TH-Niramit', 'normal')
            doc.setFontSize(fontBody.value)
            doc.text(`${i18n.t('address')} ${data.shop_address}  ${i18n.t('reportpsychotropic2_name_7')} ${data.shop_district}`, 40, 15)
            doc.text(
              `${i18n.t('reportpsychotropic2_name_8')} ${data.shop_amphoe} ${i18n.t('reportpsychotropic2_name_9')} ${
                data.shop_province
              } ${i18n.t('zip_code')} ${data.shop_zipcode}`,
              40,
              20,
            )
            doc.text(`${i18n.t('tel')} ${data.shop_tel} ${i18n.t('email')} ${data.shop_email}`, 40, 25)
            if (isShopFix && topic.value == '2') {
              doc.text(`${i18n.t('numberLicenses')} ${data.shop_license}`, 40, 30)
            } else {
              doc.text(`${i18n.t('numberLicenses')} ${data.shop_license}  ${i18n.t('tax_id')} ${data.shop_tax}`, 40, 30)
            }
            doc.setLineWidth(0.8)
            doc.setDrawColor('#1E88E5')
            doc.line(61, 34, 205, 34)
            doc.setDrawColor('#212121')
            doc.line(4, 34, 60, 34)
          } else {
            doc.setFontSize(fontTitle.value)
            doc.setFillColor('#212121')
            doc.text(`${shopData.value.shop_company_name}`, 40, 10)
            doc.setFont('TH-Niramit', 'normal')
            doc.setFontSize(fontBody.value)
            doc.text(
              `${i18n.t('address')} ${shopData.value.shop_company_address} ${i18n.t('reportpsychotropic2_name_7')} ${
                shopData.value.shop_company_district
              }`,
              40,
              15,
            )
            doc.text(
              `${i18n.t('reportpsychotropic2_name_8')} ${shopData.value.shop_company_amphoe} ${i18n.t(
                'reportpsychotropic2_name_9',
              )} ${shopData.value.shop_company_province} ${i18n.t('zip_code')} ${shopData.value.shop_company_zipcode}`,
              40,
              20,
            )
            doc.text(
              `${i18n.t('tel')} ${shopData.value.shop_company_tel} ${i18n.t('email')} ${
                shopData.value.shop_company_email
              }`,
              40,
              25,
            )
            if (isShopFix && topic.value == '2') {
              doc.text(`${i18n.t('numberLicenses')} ${data.shop_license}`, 40, 30)
            } else {
              doc.text(`${i18n.t('numberLicenses')} ${data.shop_license}  ${i18n.t('tax_id')} ${data.shop_tax}`, 40, 30)
            }
            doc.setLineWidth(0.8)
            doc.setDrawColor('#1E88E5')
            doc.line(61, 34, 205, 34)
            doc.setDrawColor('#212121')
            doc.line(4, 34, 60, 34)
          }
          doc.setLineWidth(0.1)
          doc.setDrawColor('#212121')
          doc.line(4, 85, 205, 85)
          doc.line(4, 94, 205, 94)

          // End Address Shop block

          // start Address customer

          if (route.value.query.showCompany == 'false') {
            doc.setFont('TH-Niramit', 'bold')
            doc.setFontSize(fontTitle.value)
            doc.text(`${i18n.t('contacts')} ${data.customer_name} ${i18n.t('customer_code')} ${data.customer_id}`, 5, 40)
            doc.setFont('TH-Niramit', 'normal')
            doc.setFontSize(fontBody.value)
            if (isShopFix && topic.value == '2') {
            } if (point.value == 'false') {
              doc.text(`${i18n.t('id_card')} ${data.customer_idcard}`, 5, 45)
            } else {
              let customer_idcard = "-"
              doc.text(`${i18n.t('id_card')} ${customer_idcard}`, 5, 45)
            }
            //   doc.text(`${i18n.t('id_card')} ${data.customer_idcard}`, 5, 45)
            // }  
            doc.text(`${i18n.t('address')} ${data.customer_address || ''}${data.customer_address ? ' ' : ''}${i18n.t('reportpsychotropic2_name_7')} ${data.customer_district}`, 5, 50 - isChangeY)
            doc.text(`${i18n.t('reportpsychotropic2_name_8')} ${data.customer_amphoe} ${i18n.t('reportpsychotropic2_name_9')} ${data.customer_province} ${i18n.t('zip_code')} ${data.customer_zipcode}`, 5, 55 - isChangeY)
            doc.text(`${i18n.t('tel')} ${data.customer_tel}`, 5, 60 - isChangeY)
            if (point.value == 'false') {
              doc.text(`${i18n.t('receive_points')} ${data.order_point} ${i18n.t('point')}  ${i18n.t('reward_points')} ${formatPrice(data.customer_point || 0)} ${i18n.t('point')}`, 5, 65 - isChangeY)
            }
          } else {
            doc.setFont('TH-Niramit', 'bold')
            doc.setFontSize(fontTitle.value)
            doc.text(`${data.company_name}`, 5, 40)
            doc.setFontSize(fontBody.value)
            doc.text(`${i18n.t('contacts')} ${data.customer_name} ${i18n.t('customer_code')} ${data.customer_id}`, 5, 45)
            doc.setFont('TH-Niramit', 'normal')
            if (isShopFix && topic.value == '2') {
            } else {
              doc.text(`${i18n.t('tax_id')} ${data.company_tax}`, 5, 50 - isChangeY)
            }
            doc.text(`${i18n.t('address')} ${data.company_address || ''}${data.company_address ? ' ' : ''}${i18n.t('reportpsychotropic2_name_7')} ${data.company_district}`, 5, 55 - isChangeY)
            doc.text(
              `${i18n.t('reportpsychotropic2_name_8')} ${data.company_amphoe} ${i18n.t('reportpsychotropic2_name_9')} ${
                data.company_province
              } ${i18n.t('zip_code')} ${data.company_zipcode}`,
              5,
              60 - isChangeY,
            )
            doc.text(`${i18n.t('tel')} ${data.company_tel}`, 5, 65 - isChangeY)
            if (point.value == 'false') {
              doc.text(
                `${i18n.t('receive_points')} ${data.order_pointsave} ${i18n.t('point')}  ${i18n.t(
                  'reward_points',
                )} ${formatPrice(data.customer_point || 0)} ${i18n.t('point')}`,
                5,
                70 - isChangeY,
              )
            }
          }
          doc.setFont('TH-Niramit', 'bold')
          doc.setFontSize(fontTitle.value)
          doc.setTextColor('#1565C0')
          if (topic.value == '1') {
            doc.text(`${i18n.t('receipt_tax_invoice')}`, 204, 45, null, null, 'right')
            doc.text('[RECEIPT / TAX INVOICE]', 204, 50, null, null, 'right')
          } else if (topic.value == '2') {
            doc.text(`${i18n.t('a_receipt')}`, 204, 45, null, null, 'right')
            doc.text('[RECEIPT]', 204, 50, null, null, 'right')
          } else {
            doc.text(`${i18n.t('tax_invoice')}`, 204, 45, null, null, 'right')
            doc.text('[TAX INVOICE]', 204, 50, null, null, 'right')
          }
          if (data.order_status_id == '2') {
            doc.setFontSize(20)
            doc.setTextColor('#FF1744')
            doc.text(`(${i18n.t('cancel')})`, 204, 56, null, null, 'right')
          }
          doc.setDrawColor(0)
          doc.setFillColor('#1E88E5')
          doc.rect(106, 67, 99, 16, 'F')

          doc.setTextColor('#fff')
          doc.setFont('TH-Niramit', 'bold')
          doc.setFontSize(fontTitle.value)
          doc.text(`${i18n.t('a_number')}`, 130, 73, null, null, 'left')
          doc.text(`${i18n.t('date')}`, 170, 73, null, null, 'left')
          doc.text(`${data.order_id}`, 130, 80, null, null, 'left')
          doc.text(`${sumdate(data.order_create)}`, 170, 80, null, null, 'left')
          doc.setTextColor('#1565C0')

          if (route.value.query.showPage == 'false') {
            const str = `${i18n.t('page')} ${doc.internal.getNumberOfPages()}`
            doc.setFontSize(10)
            doc.text(str, 197, 10)
          }
          lastTable.value = datax.cursor.y
          doc.setFontSize(fontBody.value)
          doc.setTextColor('#212121')
          doc.text(
            `${i18n.t('start_date_s')} ${sumdate(data.order_create)} ${i18n.t('end_date_s')} ${sumdate(data.order_create)}`,
            5,
            288,
          )
          doc.setLineWidth(0.8)
          doc.setDrawColor('#1E88E5')
          doc.line(4, 290, 60, 290)
          doc.setDrawColor('#212121')
          doc.line(61, 290, 205, 290)
        },
      })
      doc.setDrawColor(0)
      doc.setFillColor('#F5F5F5')
      doc.rect(7, lastTable.value + 3, 110, 10, 'F')
      if (isShopFix && topic.value == '2') {
        doc.setFillColor('#1E88E5')
        doc.rect(120, lastTable.value + 36, 85, 10, 'F')
      } else {
        doc.setFillColor('#1E88E5')
        doc.rect(120, lastTable.value + 44, 85, 10, 'F')
      }
      doc.setFont('TH-Niramit', 'bold')
      doc.setFontSize(fontTitle.value)
      doc.setTextColor('#212121')
      doc.text(data.net_amount_thai, 61, lastTable.value + 10, null, null, 'center')

      doc.setFontSize(13)
      doc.text(`${i18n.t('paymentSevice')} : ${data.net_amount}`,7,lastTable.value + 20)
      doc.text(`${i18n.t('paymentMethod')} : ${data.shop_bank_name || ''}`, 7, lastTable.value + 25)
      if (comment.value == 'false') {
        doc.text(`${i18n.t('note')} : ${data.order_comment}`, 7, lastTable.value + 30)
      }
      doc.setFontSize(13)
      doc.text(`${shopData.value.shop_comment ? shopData.value.shop_comment : ''}`, 7, lastTable.value + 45)
      doc.setFontSize(fontTitle.value)
      doc.setDrawColor(0)
      doc.setFillColor('#1E88E5')
      doc.rect(106, 67, 99, 16, 'F')

      doc.setTextColor('#fff')
      doc.setFont('TH-Niramit', 'bold')
      doc.setFontSize(fontTitle.value)
      doc.text(`${i18n.t('a_number')}`, 130, 73, null, null, 'left')
      doc.text(`${i18n.t('date')}`, 170, 73, null, null, 'left')
      doc.text(`${data.order_id}`, 130, 80, null, null, 'left')
      doc.text(`${sumdate(data.order_create)}`, 170, 80, null, null, 'left')
      doc.setFont('TH-Niramit', 'bold')
      doc.setTextColor('#1E88E5')
      doc.setFontSize(fontTitle.value)
      const arr = [
        {
          
          //name: `${isShopFix && topic.value && data.order_tax_id == '2' ? i18n.t('value') : i18n.t('valueBeforeVat2')}`,
          name: `${(data.order_tax_id) == '2' ? i18n.t('valueBeforeVat') : i18n.t('valueBeforeVat1')}`,
          value: data.order_price,
        },
        { name: i18n.t('discount'), value: data.order_save },
        { name: i18n.t('discount_points'), value: data.order_pointsave },
        {
          name:
            route.value.query.lang == '2'
              ? vatList.value[+data.order_tax_id - 1].en
              : vatList.value[+data.order_tax_id - 1].th,
          value: data.order_tax,
        },
        {
          //name: `${isShopFix && topic.value && data.order_tax_id == '2' ? i18n.t('total_') : i18n.t('valueBeforeVat1')}`,
          name: `${(data.order_tax_id) == '2' ? i18n.t('valueBeforeVat1') : i18n.t('valueBeforeVat')}`,
          value: data.order_total,
        },
        { name: i18n.t('netTotalPrice'), value: data.net_amount },
      ]

      if (isShopFix && topic.value == '2') {
        arr.splice(3, 1)
      }

      for (let i = 0; i < arr.length; i++) {
        const x = 8
        if (arr[i].name == i18n.t('netTotalPrice')) {
          doc.setTextColor('#fff')
        }
        doc.text(arr[i].name, 160, lastTable.value + 10 + x * i, null, null, 'right')
        doc.text(arr[i].value, 201, lastTable.value + 10 + x * i, null, null, 'right')
      }
      doc.addImage(data.qrcode_image || '', 'JPEG', 93, lastTable.value + 15, 25, 25)

      doc.setFontSize(fontTitle.value)
      doc.setTextColor('#212121')
      doc.text(i18n.t('userPrintDoc2'), 180, lastTable.value + 65, null, null, 'center')
      doc.text(i18n.t('userPrintDoc3'), 120, lastTable.value + 65, null, null, 'center')
      doc.text(i18n.t('userPrintDoc4'), 150, lastTable.value + 95, null, null, 'center')

      doc.setLineWidth(0.2) 
      doc.setDrawColor('#212121')
     
      doc.line(100, lastTable.value + 75, 139, lastTable.value + 75)
      doc.line(160, lastTable.value + 75, 200, lastTable.value + 75)
      doc.line(130, lastTable.value + 105, 170, lastTable.value + 105)
      doc.text("(" + data.customer_name + ")", 120, lastTable.value + 82, null, null, 'center')
      doc.text(sumdate(data.order_create), 120, lastTable.value + 86, null, null, 'center')
      doc.text("(...............................)", 180, lastTable.value + 82, null, null, 'center')
      doc.text(sumdate(data.order_create), 180, lastTable.value + 86, null, null, 'center')
      doc.text("(...............................)", 150, lastTable.value + 112, null, null, 'center')
      doc.text(sumdate(data.order_create), 150, lastTable.value + 116, null, null, 'center')

      // window.open(doc.output('bloburl', { filename: 'myFileName.pdf' }))

      frame.value = doc.output('datauristring', { filename: `${i18n.t('a_number')}.pdf` })
    }

    return { savePDF, frame }
  },
}
</script>
